import { fetchMessages } from 'api';
import { uniqBy } from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const ADD_MESSAGES = 'ADD_MESSAGES';
const UPDATE_FOR_CHAT = 'UPDATE_FOR_CHAT';
const READ_MESSAGE = 'READ_MESSAGE';

// ------------------------------------
// Actions
// ------------------------------------
export const loadMessages = (hotelId) => ({
  type: ADD_MESSAGES,
  payload: fetchMessages(hotelId).then(res => ({
    hotelId: hotelId,
    data: res.data
  }))
});

export const updateForChat = (message) => ({
  type: UPDATE_FOR_CHAT,
  payload: message
});

export const messageRead = (chat) => ({
  type: READ_MESSAGE,
  payload: chat
})

// ------------------------------------
// Reducer
// ------------------------------------
/* botId(pin):""
   chatType(pin):"WHATSAPP"
   hotelId(pin):1
   messages: []
   userId(pin):"393478821417"
   userName(pin):"Andrea Francesco Iuorio"
   unreadMessage: false
*/

const initialState = {
  liveChat: []
};

const getChatId = chat => chat.chatType + '_' + chat.hotelId + '_' + chat.userId;
const checkChat = (c1, c2) => c1.userId === c2.userId && c1.chatType === c2.chatType && c1.hotelId === c2.hotelId;

function handleRefreshChats(state, action) {
  const fetchedChats = action.payload.data || [];
  const newChatsMap = new Map(fetchedChats.map(chat => [getChatId(chat), chat]));
  const oldChatsMap = new Map(state.liveChat.map(chat => [getChatId(chat), chat]));

  const newChats = fetchedChats.filter(newChat => !oldChatsMap.has(getChatId(newChat)));

  // Keep only old chats that have the same hotel id as the fetched chats
  const oldUpdatedChats = state.liveChat
    .filter(oldChat => oldChat.hotelId === action.payload.hotelId)
    .map(oldChat => {
      const newChat = newChatsMap.get(getChatId(oldChat));
      if (newChat) {
        oldChat.userName = newChat.userName;
        oldChat.messages = uniqBy([...oldChat.messages, ...newChat.messages], 'id');
      }
      return oldChat;
    });
  return ({ ...state, liveChat: [...oldUpdatedChats, ...newChats] });
}

const handleUpdateForChat = (state, action) => {
  const payload = action.payload;
  const chatToUpdate = state.liveChat.find(c => checkChat(c, payload));
  if (!chatToUpdate) {
    const newChat = createChatFromUpdate(payload);
    return ({ ...state, liveChat: [...state.liveChat, newChat] });
  }
  const updatedLivechats = state.liveChat.map(chat => {
    if (checkChat(chat, payload)) {
      const newMessage = createMessageFromUpdate(payload);
      const messageIsAlreadyPresent = chat.messages.some(m => m.id === newMessage.id);
      return messageIsAlreadyPresent
        ? { ...chat, messages: chat.messages.map(m => m.id === newMessage.id ? newMessage : m) }
        : { ...chat, messages: chat.messages.concat([newMessage]), unreadMessage: true }
    }
    return chat
  });
  return ({ ...state, liveChat: updatedLivechats });
}

const handleReadMessage = (state, action) => {
  const payload = action.payload;
  const chatToUpdate = state.liveChat.find(c => checkChat(c, payload));
  if (!chatToUpdate || !chatToUpdate.unreadMessage)
    return state;

  const updatedLivechats = state.liveChat.map(c => checkChat(c, payload) ? { ...c, unreadMessage: false } : c);

  return ({ ...state, liveChat: updatedLivechats })
}

export function handleMessages(state = initialState, action) {
  if (action.type === ADD_MESSAGES)
    return handleRefreshChats(state, action);
  else if (action.type === UPDATE_FOR_CHAT)
    return handleUpdateForChat(state, action);
  else if (action.type === READ_MESSAGE)
    return handleReadMessage(state, action);

  return state;
}

function createMessageFromUpdate({ time, message, id, direction }) {
  return { id, direction, date: time, text: message };
}

function createChatFromUpdate(payload) {
  return {
    botId: '',
    chatType: payload.chatType,
    hotelId: payload.hotelId,
    messages: [createMessageFromUpdate(payload)],
    unreadMessage: true,
    userId: payload.userId
  };
}
