import React from 'react';

import { Skeleton } from '@material-ui/lab';

const StatisticsCardSkeleton = () => {
  return (
    <div>
      <Skeleton variant='rect' height={50}></Skeleton>
      <Skeleton variant='text'></Skeleton>
    </div>
  );
};

export default StatisticsCardSkeleton;
