import { fetchExtServicesList, fetchHotelList, fetchUserList } from '../../api';

// ------------------------------------
// Constants
// ------------------------------------
const GET_HOTEL_LIST = 'GET_HOTEL_LIST';
const GET_USER_LIST = 'GET_USER_LIST';
const GET_HOTEL_AND_USER_LIST = 'GET_HOTEL_AND_USER_LIST';
const GET_HOTEL_AND_EXTERNAL_SERVICE_LIST = 'GET_HOTEL_AND_EXTERNAL_SERVICE_LIST';

// ------------------------------------
// Actions
// ------------------------------------
export const loadHotelList =
  dispatch =>
    fetchHotelList().then(res => dispatch({
      type: GET_HOTEL_LIST,
      payload: res.data
    }))

export const loadUserList =
  dispatch =>
    fetchUserList().then(res => dispatch({
      type: GET_USER_LIST,
      payload: res.data
    }))

export const loadUserAndHotelList = dispatch =>
  Promise.all([fetchHotelList(), fetchUserList()]).then(
    responses => {
      dispatch({
        type: GET_HOTEL_AND_USER_LIST,
        payload: {
          hotelList: responses[0].data,
          userList: responses[1].data
        }
      })
    })

export const loadExternalServiceAndHotelList = dispatch =>
  Promise.all([fetchHotelList(), fetchExtServicesList()]).then(
    responses => {
      dispatch({
        type: GET_HOTEL_AND_EXTERNAL_SERVICE_LIST,
        payload: {
          hotelList: responses[0].data,
          externalServiceList: responses[1].data
        }
      })
    })

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  userList: [],
  hotelList: [],
  externalServiceList: []
};

export function handleBackofficeActions(state = initialState, action) {
  switch (action.type) {
    case GET_USER_LIST:
      return { ...state, userList: action.payload || [] };
    case GET_HOTEL_LIST:
      return { ...state, hotelList: action.payload || [] };
    case GET_HOTEL_AND_USER_LIST:
      return { ...state, hotelList: action.payload.hotelList || [], userList: action.payload.userList || [] };
    case GET_HOTEL_AND_EXTERNAL_SERVICE_LIST:
      return { ...state, hotelList: action.payload.hotelList || [], externalServiceList: action.payload.externalServiceList || [] };
  }

  return state;
}
