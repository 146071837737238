import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// api
import { fetchExternalServiceById, modifyExternalService } from 'api';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import ExternalServiceForm from 'views/BackOffice/ExternalServices/components/ExternalServiceForm';
import { toastr } from 'react-redux-toastr';
import BackdropSpinner from '../../../../components/BackdropSpinner';

export default function ModifyExternalService({ history, hotelList, loadHotelList }) {
  const { id } = useParams();

  const [externalService, setExternalService] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([fetchExternalServiceById(id), loadHotelList()])
      .then(res => setExternalService(res[0].data))
      .catch(err => {
        toastr.error('Failed', 'Error loading ext. services data');
        console.error(err);
        history.goBack();
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return <BackdropSpinner />;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ExternalServiceForm
            externalService={externalService}
            hotelList={hotelList}
            disableServiceName={true}
            history={history}
            headerMsg={'Modify External Service'}
            btnAction={modifyExternalService}
            btnProps={{
              btnText: 'Modify External Service',
              successMsg: 'External Service modified',
              failureMsg: 'Unable to modify external service'
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

ModifyExternalService.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  hotelList: PropTypes.array,
  loadHotelList: PropTypes.func
}
