import React from 'react';
import PropTypes from 'prop-types';

import { toastr } from 'react-redux-toastr';
import { pick } from 'lodash';

// api
import { modifyUserHotelConfig, getUserHotelConfig } from 'api';

// style
import { makeStyles } from '@material-ui/core/styles';

// components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import SpinnerButton from 'components/CustomButtons/SpinnerButton';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import LiveChatAvailability from 'components/LiveChatAvailability';
import BackdropSpinner from 'components/BackdropSpinner';
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,0.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Rubik', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  }
};

const useStyles = makeStyles(styles);

const ConfigurationHotel = ({ hotelId, history, updateBotSettingHotel }) => {
  const classes = useStyles();

  const [liveChatForm, setLiveChatForm] = React.useState({
    id: hotelId,
    liveChatAvailabilityType: 'ALWAYS',
    liveChatTimeSlotList: []
  });

  const [botForm, setBotForm] = React.useState({
    id: hotelId,
    botEnabled: false
  });

  const [pristine, setPristine] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleLiveChatBeChanges = data => {
    setLiveChatForm({
      ...liveChatForm,
      ...data
    });
    setPristine(false);
  };

  const handleHotelModification = () => {
    return modifyUserHotelConfig(liveChatForm)
      .then(() => setPristine(true))
      .catch(() => toastr.error('Failed', 'Error updating hotel'));
  };

  const submitBotChange = () => {
    const newBotForm = {
      ...botForm,
      botEnabled: !botForm.botEnabled
    };
    setBotForm(newBotForm);
    modifyUserHotelConfig(newBotForm)
      .then(() => updateBotSettingHotel(newBotForm.id, newBotForm.botEnabled))
      .catch(() => {
        toastr.error('Failed', 'Error updating hotel bot settings');
        setBotForm({
          ...botForm,
          botEnabled: !newBotForm.botEnabled
        });
      });
  };

  React.useEffect(() => {
    if (hotelId) {
      setLoading(true);
      getUserHotelConfig(hotelId).then(res => {
        setLiveChatForm({
          ...liveChatForm,
          ...pick(res.data, Object.keys(liveChatForm))
        });
        setBotForm({
          ...botForm,
          ...pick(res.data, Object.keys(botForm))
        });
      }).catch(err => {
        toastr.error('Failed', 'Error retrieving hotel config');
        console.error(err);
        history.goBack();
      }).finally(() => setLoading(false));
    }
  }, [hotelId]);

  const disableHotelSubmit =
    pristine ||
    !liveChatForm.liveChatAvailabilityType;

  if (loading)
    return <BackdropSpinner/>;

  return (
    <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>{'Hotel bot settings'}</h4>
            </CardHeader>
            <CardBody>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={botForm.botEnabled} onChange={submitBotChange} color="primary"/>}
                  label={'Bot active status'}
                  labelPlacement="start"
                />
              </FormGroup>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>{'Hotel LiveChat Availability settings'}</h4>
            </CardHeader>
            <CardBody>
              <LiveChatAvailability
                liveChatAvailabilityType={liveChatForm.liveChatAvailabilityType}
                liveChatTimeSlotList={liveChatForm.liveChatTimeSlotList}
                handleBeChanges={handleLiveChatBeChanges}
              />
            </CardBody>
            <CardFooter>
              <SpinnerButton
                onClick={handleHotelModification}
                disabled={disableHotelSubmit}
                btnText='Update Hotel'
                successMsg='Hotel Updated'
                failureMsg='Unable to update hotel'
              />
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default ConfigurationHotel;

ConfigurationHotel.propTypes = {
  hotelId: PropTypes.number,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  updateBotSettingHotel: PropTypes.func
};
