import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { parseISO, isWithinInterval, addYears, subDays, differenceInMinutes } from 'date-fns';
import { toastr } from 'react-redux-toastr';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Avatar } from '@material-ui/core';
import { WhatsApp, Facebook, Done, AccessTime, Error, DoneAll } from '@material-ui/icons';

import TTHDatePicker from 'components/TTHDatePicker/TTHDatePicker';
import TTHSearchField from 'components/TTHSearchField';
import PerfectScrollView from 'components/PerfectScrollView';
import TextModal from 'components/TextModal';
import { dateComparatorReverse, dateComparator } from 'utils/comparators';
import { formatMessageTimestamp, formatMessageTimestampDistance } from 'utils/formatters';
import SendMessageBox from './sendMessageBox';
import SessionExpiredMessageBox from './sessionExpired';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LoadingWrapper from '../../components/LoadingWrapper';
import ContentSpinner from '../../components/ContentSpinner';

import logo from 'logo.svg';
import messagesStyle from './style';

const useLocalStyles = makeStyles(messagesStyle);

// TODO This filter should be on the BE side
const chatHasMessageInDateInterval = (chat, interval) => !!chat.messages.find(val => isWithinInterval(parseChatDate(val.date), interval));
const parseChatDate = (date) => {
  const newDate = parseISO(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}
export default function MessagesComponent({ liveChats, currentHotelId, messageRead, loadMessages }) {
  const classes = useLocalStyles();
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(subDays(new Date(), 90));
  const [selectedDateTo, setSelectedDateTo] = React.useState(new Date());
  const [selectedChat, setSelectedChat] = React.useState(null);
  const [fullTextSearch, setFullTextSearch] = React.useState(null);
  const [messageToSend, setMessageToSend] = React.useState('');
  const [filteredLiveChats, setFilteredLiveChats] = React.useState([]);
  const [warningModal, setWarningModal] = React.useState({ open: false, newChat: undefined });
  const [loading, setLoading] = React.useState(false);

  const filterChats = chat => {
    let interval = null;
    if (selectedDateFrom || selectedDateTo) {
      interval = {
        start: selectedDateFrom || new Date(1),
        end: selectedDateTo || addYears(new Date(), 1)
      };
    }

    return (!interval || chatHasMessageInDateInterval(chat, interval)) &&
      (!fullTextSearch || JSON.stringify(chat).toLocaleLowerCase().includes(fullTextSearch.toLocaleLowerCase()))
  };

  React.useEffect(() => {
    setLoading(true);
    loadMessages(currentHotelId)
      .catch(err => {
        toastr.error('Failed', 'Error loading hotel chat messages');
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [currentHotelId]);

  React.useEffect(() => {
    const sortedLiveChats = liveChats.map(chat => ({
      ...chat,
      messages: chat.messages.sort((a, b) => dateComparator(a.date, b.date)),
      lastMessage: chat.messages[chat.messages.length - 1]
    })).sort((a, b) => dateComparatorReverse(a.lastMessage.date, b.lastMessage.date));

    const filteredLiveChats = sortedLiveChats.filter(filterChats);

    if (selectedChat) {
      const currentChat = filteredLiveChats.find(chat => chat.userId === selectedChat.userId);
      setSelectedChat(currentChat);
    }
    setFilteredLiveChats(filteredLiveChats);
  }, [liveChats, fullTextSearch, selectedDateFrom, selectedDateTo]);

  React.useEffect(() => {
    if (selectedChat) messageRead(selectedChat);
  }, [liveChats]);

  const onSearchTextChange = e => {
    setFullTextSearch(e.target.value);
  };

  const changeSelectedChat = chat => {
    setSelectedChat(chat);
    messageRead(chat);
    setMessageToSend('');
  };

  const handleDateFromChange = (date) => {
    if (date.getTime() !== selectedDateFrom.getTime()) setSelectedDateFrom(date);
    if (date.getTime() > selectedDateTo.getTime()) setSelectedDateFrom(selectedDateTo)
  };
  const handleDateToChange = (date) => {
    if (date.getTime() !== selectedDateTo.getTime()) setSelectedDateTo(date);
    if (date.getTime() < selectedDateFrom.getTime()) setSelectedDateTo(selectedDateFrom)
  };

  const checkIfShowModal = chat => {
    if (messageToSend)
      setWarningModal({ open: true, newChat: chat });
    else
      changeSelectedChat(chat);
  };

  const lastMessageDifferenceInMinutes = selectedChat
    ? differenceInMinutes(new Date(), parseISO(selectedChat.lastMessage.date))
    : 0

  const lastMessageOlderThanOneDay = lastMessageDifferenceInMinutes > (23 * 60 + 55); // 23:55 in minutes

  return (
    <Card className={classNames(classes.card, classes.maxFullHeight)} >
      <CardContent className={classNames(classes.cardBody, classes.noPaddingRight, classes.noPaddingLeft)}>
          <Grid container>
            <Grid item xs={6} sm={6} md={2} className={classNames(classes.searchContainer, classes.fieldContainer)}>
              <div className={classes.label}>Search</div>
              <TTHSearchField onChange={onSearchTextChange}/>
            </Grid>
            <Grid item xs={6} sm={6} md={10} className={classes.fieldContainer}>
              <div className={classes.label}>Choose a period of time:</div>
              <Grid container direction='row' alignItems='center'>
                <TTHDatePicker
                  value={selectedDateFrom}
                  onChange={handleDateFromChange}
                  maxDate={selectedDateTo}
                />
                <div className={classes.label} style={{ margin: '10px' }}><span>to</span></div>
                <TTHDatePicker
                  value={selectedDateTo}
                  onChange={handleDateToChange}
                  minDate={selectedDateFrom}
                />
              </Grid>
            </Grid>
          </Grid>
          <LoadingWrapper loading={loading} loadingComponent={<ContentSpinner divClasses={classes.chatHeight} spinnerSize={70} spinnerThickness={4.2}/>}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} className={classNames(classes.fieldContainer, classes.chatHeight, classes.noPaddingLeft, classes.noPaddingRight)} >
                <PerfectScrollView>
                  <Grid container>
                    {filteredLiveChats.map((chat, idx) => (
                      <Grid item xs={12} key={chat.lastMessage.id}>
                        <Grid container
                              className={messageItemClasses(chat.lastMessage.id, idx, selectedChat, liveChats, classes)}
                              onClick={() => checkIfShowModal(chat)}>

                          <Avatar className={classes.messageAvatar}>{userAvatar(chat.userName)}</Avatar>

                          <div className={classes.messageDetails}>
                            <div className={classes.nameAndTimestamp}>
                              <div className={classes.sender}>{chat.userName}</div>
                              <div className={classes.chatTimestampWrapper}>
                                {chat.chatType === 'CHATFUEL' && <Facebook fontSize='small' style={{ marginRight: '5px', color: '#4267B2' }} />}
                                {chat.chatType === 'WHATSAPP' && <WhatsApp fontSize='small' style={{ marginRight: '5px', color: '#25D366' }} />}
                                <div className={classes.timestamp}>
                                  <span>{formatMessageTimestampDistance(chat.lastMessage.date)}</span>
                                </div>
                              </div>
                            </div>
                            <div className={classes.preview}>
                              {chat.lastMessage.text}
                              {chat.unreadMessage && <FiberManualRecordIcon color="primary" fontSize="small"/>}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </PerfectScrollView>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classNames(classes.chatContainer, classes.chatHeight)}>
                {selectedChat &&
                  <PerfectScrollView startAtEnd={true}>
                    <div style={{ height: '24px' }} />
                      {selectedChat.messages.map(message =>
                        <Grid container style={{ marginTop: '10xp', marginBottom: '10px', flexWrap: 'nowrap' }} key={message.id}>
                          { message.direction === 'INBOUND' &&
                              <Avatar className={classNames(classes.chatAvatar, classes.marginRight10)}>{userAvatar(selectedChat.userName)}</Avatar>
                          }
                          <div className={classes.chatMessageBox}>
                            <div>{buildTextSpan(message.text)}</div>
                            <div className={classes.messageSentDateTime}>
                              {formatMessageSender(message)}
                              {formatMessageTimestamp(message.date)}
                              {buildMessageStatus(message, classes)}
                            </div>
                          </div>
                          { message.direction === 'OUTBOUND' &&
                              <Avatar className={classNames(classes.chatAvatar, classes.marginLeft10, classes.noBackground)}><img src={logo} /></Avatar>
                          }
                        </Grid>
                      )}
                      { selectedChat.chatType === 'WHATSAPP' && !lastMessageOlderThanOneDay &&
                          <SendMessageBox
                            currentHotelId={currentHotelId}
                            selectedChat={selectedChat}
                            messageToSend={messageToSend}
                            setMessageToSend={setMessageToSend}
                          />
                      }
                      { selectedChat.chatType === 'WHATSAPP' && lastMessageOlderThanOneDay &&
                          <SessionExpiredMessageBox />
                      }
                  </PerfectScrollView>
                }
              </Grid>
              {selectedChat &&
                <Grid item xs={12} sm={6} md={2} className={classNames(classes.detailSection)}>
                  <Avatar className={classes.chatDetailAvatar}>{userAvatar(selectedChat.userName)}</Avatar>
                  <div className={classes.chatDetailSenderName}>{selectedChat.userName}</div>
                  <div className={classes.chatDetailContactData}>
                    {selectedChat.chatType === 'WHATSAPP'
                      ? <div>
                          <div>Phone Number</div>
                          <div className={classes.chatDetailContactDataValue}>{'+' + selectedChat.userId}</div>
                        </div>
                      : null
                    }
                  </div>
                  <div style={{ height: '24px' }}>
                    {selectedChat.chatType === 'CHATFUEL' &&
                    <a style={{ float: 'left' }}
                       href={
                         'https://dashboard.chatfuel.com/#/bot/' + selectedChat.botId + '/livechat' +
                         '?conversationId=' + selectedChat.userId + '&folder=all&platform=facebook'
                       }
                       target={'_blank'}
                       rel={'noreferrer'}>
                      Go to chat
                    </a>
                    }
                  </div>
                </Grid>
              }
            </Grid>
          </LoadingWrapper>
        <TextModal
          open={warningModal.open}
          onClose={() => { setWarningModal({ open: false, newChat: undefined }); }}
          onOk={() => { changeSelectedChat(warningModal.newChat); }}
          textMessage={'A draft message is present. Do you want to exit?'}
          />
      </CardContent>
    </Card>
  );
}

MessagesComponent.propTypes = {
  liveChats: PropTypes.arrayOf(PropTypes.shape({
    chatType: PropTypes.string,
    userId: PropTypes.string,
    userName: PropTypes.string,
    botId: PropTypes.string,
    unreadMessage: PropTypes.bool,
    messages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      text: PropTypes.string,
      direction: PropTypes.string
    }))
  })),
  updateForChat: PropTypes.func,
  currentHotelId: PropTypes.number,
  messageRead: PropTypes.func,
  loadMessages: PropTypes.func
};

const messageItemClasses = (messageId, idx, selectedMessage, messages, classes) => {
  const additionalClasses = [];
  if (idx === 0)
    additionalClasses.push(classes.firstMessage);

  else if (idx === messages.length - 1)
    additionalClasses.push(classes.lastMessage);

  if (selectedMessage && selectedMessage.lastMessage && messageId === selectedMessage.lastMessage.id)
    additionalClasses.push(classes.selectedMessage);

  return classNames(classes.listItem, ...additionalClasses);
};

const userAvatar = name => {
  if (!name || !name.trim())
    return '';

  return name.match(/\b(\w)/g).join('').toUpperCase();
};

const buildTextSpan = text =>
  (text || '').split(/[\r\n]+/)
    .map((s, i) => <span key={`span-${i}`}>{s}</span>)
    .reduce((acc, curr, i) => {
      acc.push(curr);
      acc.push(<br key={`br-${i}`} />);
      return acc;
    }, []);

const buildMessageStatus = (message, classes) => {
  if (message.direction === 'INBOUND')
    return <div/>

  if (!message.deliverStatus) return <AccessTime className={classes.chatDeliverStatus}/>;
  if (message.deliverStatus === 'DELIVERED') return <Done className={classes.chatDeliverStatus} color={'primary'}/>;
  if (message.deliverStatus === 'FAILED') return <Error className={classes.chatDeliverStatus} color={'error'}/>;
  if (message.deliverStatus === 'READ') return <DoneAll className={classes.chatDeliverStatus} color={'primary'}/>;
};

const formatMessageSender = (message) => {
  if (message.direction === 'INBOUND') return '';

  return 'Sent by ' + (message.dashbotSenderName || 'bot') + ' - ';
}
