import PropTypes from 'prop-types';

const LoadingWrapper = ({ loading = false, loadingComponent = null, children }) => {
  if (loading)
    return loadingComponent;
  else
    return children;
};

LoadingWrapper.propTypes = {
  loading: PropTypes.bool,
  loadingComponent: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default LoadingWrapper;
