import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import SortedTable from 'components/Tables/SortedTable';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import TTHSearchField from 'components/TTHSearchField';
import Button from 'components/CustomButtons/Button';
import MenuButton from 'components/MenuButton';
import MultipleSelect from 'components/MultipleSelect';
import { stringComparator } from 'utils/comparators';
import ContentSpinner from '../../../../components/ContentSpinner';
import { toastr } from 'react-redux-toastr';

const useStyle = makeStyles(styles);

const ExternalServicesListTable = ({ history, hotelList, externalServiceList, loadExternalServiceAndHotelList }) => {
  const [fullTextSearch, setFullTextSearch] = React.useState();
  const [selectedHotel, setSelectedHotel] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    loadExternalServiceAndHotelList()
      .catch(err => {
        toastr.error('Failed', 'Error loading ext. services data');
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const classes = useStyle();

  const ExternalServiceButton = dto => <MenuButton menu={[
    {
      id: dto.id,
      onClick: () => { history.push('/backoffice/externalservices/modify/' + dto.id); },
      text: 'Modify'
    }
  ]}/>;

  const tableHeaderData = [
    { id: 'serviceName', text: 'Name', sortable: true, comparator: stringComparator },
    { id: 'email', text: 'Email', sortable: true, comparator: stringComparator },
    { id: 'action', text: 'Action', sortable: false, comparator: undefined, render: ExternalServiceButton }
  ];

  const handleSearchChange = (event) => {
    setFullTextSearch(event.target.value);
  }

  const filterExtServiceForText = dto => {
    return !fullTextSearch || JSON.stringify(dto).toLocaleLowerCase().includes(fullTextSearch.toLocaleLowerCase());
  };

  const filterServiceForHotels = dto => {
    return selectedHotel.length === 0 || dto.hotels.map(hotel => hotel.id).some(id => selectedHotel.indexOf(id) > -1);
  }

  const filteredData = !loading ? externalServiceList.filter(filterExtServiceForText).filter(filterServiceForHotels) : [];

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.card}>
          <CardBody className={classes.cardBody}>
            <Grid container>
              <Grid item xs={6} sm={6} md={4} style={{ padding: '10px 10px 10px 0px' }}>
                <div className={classes.label}>Search</div>
                <TTHSearchField
                  onChange={handleSearchChange}
                  style={{ width: '100%', boxSizing: 'border-box', padding: '10px 20px', height: 'initial' }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} style={{ padding: '10px 10px 10px 0px' }}>
                <div className={classes.label}>Hotel:</div>
                <MultipleSelect
                  dataList={hotelList}
                  selectedElements={selectedHotel}
                  setSelectedElements={setSelectedHotel}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} style={{
                padding: '10px 0px 10px 10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end'
              }}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => { history.push('/backoffice/externalservices/create') }}
                >
                  Add External Service
                </Button>
              </Grid>
            </Grid>
            <SortedTable
              tableData={filteredData}
              tableHeaderData={tableHeaderData}
              orderDirection={{ columnId: 'name', dir: 'asc' }}
            />
            <ContentSpinner show={loading} style={{ height: 60 }}/>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ExternalServicesListTable.propTypes = {
  hotelList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  externalServiceList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    serviceName: PropTypes.string,
    email: PropTypes.string,
    hotels: PropTypes.arrayOf(PropTypes.object)
  })),
  loadExternalServiceAndHotelList: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default ExternalServicesListTable;
