import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import Navbar from 'components/Header';
import Sidebar from 'components/Sidebar';
import PerfectScrollView from 'components/PerfectScrollView';

import { dashboardRoutes as routes } from 'routes';

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';
import { getWebsocketUrl } from 'utils/websockets';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === '/dashboard') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/dashboard" to="/dashboard/home" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Dashboard ({ loadUser, updateForChat, loadMessages, currentHotelId, ...rest }) {
  // styles
  const classes = useStyles();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.useEffect(() => {
    loadUser();
  }, []);

  React.useEffect(() => {
    const websocket = new WebSocket(getWebsocketUrl('/api/messages/updates'));
    websocket.onmessage = msg => {
      const messageData = JSON.parse(msg.data);
      if (currentHotelId === messageData.hotelId)
        updateForChat(messageData);
    }
    return () => websocket.close();
  }, [currentHotelId]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960)
      setMobileOpen(false);
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  },
  []
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        {...rest}
      />
      <PerfectScrollView className={classes.mainPanel} >
        <Navbar
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          {switchRoutes}
        </div>
      </PerfectScrollView>
    </div>
  );
};

Dashboard.propTypes = {
  loadUser: PropTypes.func,
  updateForChat: PropTypes.func,
  loadMessages: PropTypes.func,
  currentHotelId: PropTypes.number
};
