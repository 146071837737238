import { loadHotelList as loadHotelListApi } from 'store/reducer/backofficeReducer';
import { connect } from 'react-redux';
import ModifyExternalService from './component';

// read from redux store
const mapStateToProps = (state) => ({
  hotelList: state.backofficeData.hotelList
})

// dispatch actions to redux store
const mapDispatchToProps = (dispatch) => ({
  loadHotelList: () => loadHotelListApi(dispatch)
});

// container component/smart component (interacts with redux)
const ModifyExternalServicePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyExternalService);

export default ModifyExternalServicePage;
