import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
// api
import { fetchUserWithId, modifyUser } from 'api';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import UserForm from 'views/BackOffice/Users/components/UserForm';
import BackdropSpinner from '../../../../components/BackdropSpinner';

export default function ModifyUser({ history, hotelList, loadHotelList }) {
  const { id } = useParams();

  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([fetchUserWithId(id), loadHotelList()])
      .then(res => setUser(res[0].data))
      .catch(err => {
        toastr.error('Failed', 'Error loading user data');
        console.error(err);
        history.goBack();
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return <BackdropSpinner />;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <UserForm
            user={user}
            hotelList={hotelList}
            editablePassword={false}
            disableMail={true}
            history={history}
            headerMsg={'Modify Profile'}
            btnAction={modifyUser}
            btnProps={{
              btnText: 'Modify User',
              successMsg: 'User modified',
              failureMsg: 'Unable to modify user'
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

ModifyUser.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  hotelList: PropTypes.array,
  loadHotelList: PropTypes.func
}
