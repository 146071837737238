import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { primaryColor } from '../../assets/jss/material-dashboard-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'inherit',
    width: '100%',
    height: '100%'
  }
}));

const BackdropSpinner = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open invisible>
      <CircularProgress style={{ color: primaryColor[0] }} size={80} thickness={4.5} />
    </Backdrop>
  );
};

export default BackdropSpinner;
