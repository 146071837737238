import { connect } from 'react-redux';

import ConfigurationHotel from './component';
import { updateBotSettingHotel } from '../../store/reducer/userReducer';

// read from redux store
const mapStateToProps = (state) => ({
  hotelId: state.hotel
});

const mapDispatchToProps = {
  updateBotSettingHotel
};

// container component/smart component (interacts with redux)
const UserConfigurationContainer = connect(
  mapStateToProps, mapDispatchToProps
)(ConfigurationHotel);

export default UserConfigurationContainer;
