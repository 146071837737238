import { fetchMe } from 'api';
import { ADD_USER, UPDATE_BOT_SETTING_HOTEL } from 'store/reducer/actions';
import { omit } from 'lodash';

// ------------------------------------
// Actions
// ------------------------------------
export const loadUser = () => ({
  type: ADD_USER,
  payload: fetchMe()
});

export const updateBotSettingHotel = (hotelId, botEnabled) => ({
  type: UPDATE_BOT_SETTING_HOTEL,
  payload: {
    id: hotelId,
    botEnabled: botEnabled
  }
})

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  user: {},
  hotels: []
};

export function handleUserFetched(state = initialState, action) {
  if (action.type === ADD_USER) {
    const userData = action.payload.data;
    if (userData) {
      return {
        ...state,
        user: {
          avatar: userData.name.toUpperCase().charAt(0) + userData.surname.toUpperCase().charAt(0),
          isAdmin: userData.userType === 'ADMIN',
          ...omit(userData, 'hotels')
        },
        hotels: userData.hotels || []
      };
    }
  } else if (action.type === UPDATE_BOT_SETTING_HOTEL) {
    return {
      ...state,
      hotels: state.hotels.map(hotel => {
        if (hotel.id === action.payload.id)
          return { ...hotel, botEnabled: action.payload.botEnabled };
        return hotel;
      })
    }
  }
  return state;
}
