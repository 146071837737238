import { loadHotelList as loadHotelListApi } from 'store/reducer/backofficeReducer';
import { connect } from 'react-redux';

import CreateUserComponent from './component';

// read from redux store
const mapStateToProps = (state) => ({
  hotelList: state.backofficeData.hotelList
})

// dispatch actions to redux store
const mapDispatchToProps = (dispatch) => ({
  loadHotelList: () => loadHotelListApi(dispatch)
});

// container component/smart component (interacts with redux)
const CreateUserPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserComponent);

export default CreateUserPage;
