import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel, FormLabel, ImageList, ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { dangerColor } from 'assets/jss/material-dashboard-react';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import SpinnerButton from 'components/CustomButtons/SpinnerButton';
import CardFooter from 'components/Card/CardFooter';

import { fetchExternalServiceByName } from 'api';
import { validateNumber, isMailValidOrEmpty } from 'utils/validators';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Rubik', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  asterisk: {
    color: dangerColor[0]
  }
};

const useStyles = makeStyles(styles);

const isTemplateCodeValidOrEmpty = tCode => {
  if (tCode === '')
    return true;
  return validateNumber(tCode)
}

const defaultExternalService = { serviceName: '', email: '', hotels: [], templateCode: '' };

export default function ExternalServiceFormComponent({
  history,
  externalService,
  hotelList,
  disableServiceName,
  headerMsg,
  btnAction,
  btnProps
}) {
  const [form, setForm] = React.useState(defaultExternalService);
  const [showNameError, setShowNameError] = React.useState(false);
  const [showEmailError, setShowEmailError] = React.useState(false);
  const [showTemplateCodeError, setTemplateCodeError] = React.useState(false);
  const [selectedHotel, setSelectedHotel] = React.useState([]);
  const [pristine, setPristine] = React.useState(true);
  const classes = useStyles();

  React.useEffect(() => {
    if (externalService) {
      setForm({ ...defaultExternalService, ...externalService });
      setSelectedHotel(externalService.hotels || []);
    }
  }, [externalService]);

  React.useEffect(() => {
    if (disableServiceName)
      return;

    if (form.serviceName) {
      fetchExternalServiceByName(form.serviceName).then(res => {
        if (res.status === 200)
          setShowNameError(true);
        else if (res.status === 204)
          setShowNameError(false);
      });
    } else
      setShowNameError(false);
  }, [disableServiceName, form.serviceName]);

  React.useEffect(() => {
    setShowEmailError(!isMailValidOrEmpty(form.email));
  }, [form.email])

  React.useEffect(() => {
    setTemplateCodeError(!isTemplateCodeValidOrEmpty(form.templateCode));
  }, [form.templateCode])

  const handleChange = event => {
    setForm({ ...form, [event.target.id]: event.target.value });
    setPristine(false);
  };

  const handleSelectHotel = hotelId => event => {
    const newSelectedHotel = event.target.checked ? [...selectedHotel, { id: hotelId }] : selectedHotel.filter(hotel => hotel.id !== hotelId);
    setSelectedHotel(newSelectedHotel);
    setForm({ ...form, hotels: newSelectedHotel })
    setPristine(false);
  };

  const disableSubmit = pristine || !form.serviceName || !form.email || showNameError || showEmailError || showTemplateCodeError;

  const handleSaveBtn = () => {
    return btnAction(
      {
        ...form,
        templateCode: parseInt(form.templateCode) || null
      }
    ).then(() => history.goBack());
  };

  return (
    <Card>
      <CardHeader color='primary'>
        <h4 className={classes.cardTitleWhite}>{headerMsg}</h4>
        <p className={classes.cardCategoryWhite}>Complete user profile</p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText='Service Name'
              id='serviceName'
              required={true}
              error={showNameError}
              errorMessage={showNameError ? 'Service already registered' : null}
              inputProps={{
                value: form.serviceName,
                onChange: handleChange,
                disabled: disableServiceName
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText='Email address'
              id='email'
              required={true}
              error={showEmailError}
              errorMessage={showEmailError ? 'Email invalid' : null}
              inputProps={{
                value: form.email,
                onChange: handleChange
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText='Template Code'
              id='templateCode'
              required={false}
              error={showTemplateCodeError}
              errorMessage={showTemplateCodeError ? 'Template Code should be numeric' : null}
              inputProps={{
                value: form.templateCode,
                onChange: handleChange
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <p>
              <FormLabel
                style={{ color: '#A9AEBC' }}
                classes={{
                  asterisk: classes.asterisk
                }}>
                Select hotels
              </FormLabel>
            </p>
          </GridItem>
          <ImageList style={{
            width: '100%',
            height: '173px',
            padding: '0 15px',
            border: '1px solid #A9AEBC',
            borderRadius: '5px'
          }} cols={4}>
            {hotelList.map((hotel) => (
              <ImageListItem key={hotel.id} cols={1} style={{ height: '61px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedHotel.map(hotel => (hotel.id)).indexOf(hotel.id) > -1}
                      onChange={handleSelectHotel(hotel.id)}
                      color='primary'
                    />}
                  label={hotel.name}
                  labelPlacement='end'
                  value='end'
                />
              </ImageListItem>
            ))}
          </ImageList>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <Button
          color='info'
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
        <SpinnerButton
          onClick={handleSaveBtn}
          disabled={disableSubmit}
          {...btnProps}
        />
      </CardFooter>
    </Card>
  );
}

ExternalServiceFormComponent.propTypes = {
  externalService: PropTypes.object,
  hotelList: PropTypes.array,
  disableServiceName: PropTypes.bool,
  headerMsg: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  btnAction: PropTypes.func,
  btnProps: PropTypes.any
};

ExternalServiceFormComponent.defaultProps = {
  externalService: null,
  disableServiceName: false
};
