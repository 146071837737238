import React, { useState } from 'react';
import PropTypes from 'prop-types';
// api
import { createExternalService } from 'api';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

import ExternalServiceForm from 'views/BackOffice/ExternalServices/components/ExternalServiceForm';
import { toastr } from 'react-redux-toastr';
import BackdropSpinner from '../../../../components/BackdropSpinner';

export default function CreateExternalService({ history, hotelList, loadHotelList }) {
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    loadHotelList()
      .catch(err => {
        toastr.error('Failed', 'Error loading hotel list');
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return <BackdropSpinner />;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ExternalServiceForm
            history={history}
            hotelList={hotelList}
            headerMsg={'Create External Service'}
            btnAction={createExternalService}
            btnProps={{
              btnText: 'Create External Service',
              successMsg: 'External Service Created',
              failureMsg: 'Unable to create a new external service'
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

CreateExternalService.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  hotelList: PropTypes.array,
  loadHotelList: PropTypes.func
}
