import { connect } from 'react-redux';
import { selectedHotel } from 'selectors/hotelSelector';
import { changeHotel } from 'store/reducer/hotelReducer';

import HotelNavigation from './component';

const mapStateToProps = (state, props) => ({
  hotelList: state.identity && state.identity.hotels,
  selectedHotel: selectedHotel(state, props)
})

const mapDispatchToProps = {
  changeHotel
};

const HotelList = connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelNavigation);

export default HotelList;
