import { loadHotelList as loadHotelListApi } from 'store/reducer/backofficeReducer';
import { connect } from 'react-redux';

import ModifyUserComponent from './component';

// read from redux store
const mapStateToProps = (state) => ({
  hotelList: state.backofficeData.hotelList
})

// dispatch actions to redux store
const mapDispatchToProps = (dispatch) => ({
  loadHotelList: () => loadHotelListApi(dispatch)
});

// container component/smart component (interacts with redux)
const ModifyUserPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyUserComponent);

export default ModifyUserPage;
