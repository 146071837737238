import React from 'react';
import PropTypes from 'prop-types';

// api
import { modifyHotel, getHotelById } from 'api';

// components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import SpinnerButton from 'components/CustomButtons/SpinnerButton';
import BackdropSpinner from '../../../../components/BackdropSpinner';

// view components
import HotelFormBody from 'views/BackOffice/Hotels/components/HotelFormBody';
import HotelFormHeader from 'views/BackOffice/Hotels/components/HotelFormHeader';
import ModalApiKey from 'views/BackOffice/Hotels/components/ModalApiKey';
import { toastr } from 'react-redux-toastr';

const ModifyHotel = ({ history, match }) => {
  const [form, setForm] = React.useState({
    id: -1,
    chatbotId: '',
    installationName: '',
    mailCc: '',
    mailCcn: '',
    mailTo: '',
    name: '',
    phoneNumber: '',
    bookingEngineType: '',
    bookingEngineConfig: '',
    whatsappNumber: '',
    whatsappNlpActive: false,
    timezone: '',
    isoCountryCode: '',
    liveChatAvailabilityType: 'ALWAYS',
    liveChatTimeSlotList: [],
    facebookAccessToken: ''
  });
  const [showApiKey, setShowApikey] = React.useState(false);
  const [pristine, setPristine] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const hotelId = Number(match.params.id);

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.id]: event.target.value
    });
    setPristine(false);
  };

  const handleBeChanges = bookingEngineInfos => {
    setForm({
      ...form,
      ...bookingEngineInfos
    });
    setPristine(false);
  };

  const handleHotelCreation = () => {
    return modifyHotel(form).then(() => history.goBack());
  };

  React.useEffect(() => {
    setLoading(true);
    getHotelById(hotelId).then(res => {
      setForm({
        ...form,
        ...res.data
      });
    })
      .catch(err => {
        toastr.error('Failed', 'Error loading hotel data');
        console.error(err);
        history.goBack();
      })
      .finally(() => setLoading(false));
  }, []);

  const disableSubmit =
    pristine ||
    !form.installationName ||
    !form.name ||
    !form.mailTo ||
    !form.bookingEngineType ||
    !form.timezone ||
    !form.isoCountryCode ||
    !form.liveChatAvailabilityType ||
    Object.values(JSON.parse(form.bookingEngineConfig)).some(x => !x);

  if (loading)
    return <BackdropSpinner />

  return (
    <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <HotelFormHeader title='Modify Hotel' subtitle='Modify hotel information' />
            <HotelFormBody
              form={form}
              handleChange={handleChange}
              handleBeChanges={handleBeChanges}
              disableInstallationName={true}
            />
            <ModalApiKey
              open={showApiKey}
              onClose={ () => { setShowApikey(false); }}
              hotelId={hotelId}
            />
            <CardFooter>
              <Button
                color='info'
                onClick={() => {
                  history.goBack()
                }}
              >
                Back
              </Button>
              <Button
                color='info'
                onClick={() => {
                  setShowApikey(true);
                }}
              >
                Show Api Key
              </Button>
              <SpinnerButton
                onClick={handleHotelCreation}
                disabled={disableSubmit}
                btnText='Update Hotel'
                successMsg='Hotel Updated'
                failureMsg='Unable to update hotel'
              />
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default ModifyHotel;

ModifyHotel.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
};
