import React, { useState } from 'react';
// api
import { createUser } from 'api';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import PropTypes from 'prop-types';
import UserForm from 'views/BackOffice/Users/components/UserForm';
import { toastr } from 'react-redux-toastr';
import BackdropSpinner from '../../../../components/BackdropSpinner';

export default function CreateUser({ history, hotelList, loadHotelList }) {
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    loadHotelList()
      .catch(err => {
        toastr.error('Failed', 'Error loading hotel list');
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return <BackdropSpinner />;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <UserForm
            history={history}
            hotelList={hotelList}
            headerMsg={'Create Profile'}
            btnAction={createUser}
            btnProps={{
              btnText: 'Create User',
              successMsg: 'User Created',
              failureMsg: 'Unable to create a new user'
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

CreateUser.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  hotelList: PropTypes.array,
  loadHotelList: PropTypes.func
}
