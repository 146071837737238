import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { primaryColor } from '../../assets/jss/material-dashboard-react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  spinnerDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinner: {
    color: primaryColor[0]
  }
}));

const ContentSpinner = ({ show, style, divClasses, spinnerSize, spinnerThickness }) => {
  const classes = useStyles();

  if (!show)
    return null;

  return (
    <div className={classNames(classes.spinnerDiv, divClasses)} style={style}>
      <CircularProgress className={classes.spinner} size={spinnerSize} thickness={spinnerThickness} />
    </div>
  );
};

ContentSpinner.defaultProps = {
  show: true,
  divClasses: '',
  spinnerSize: 40,
  spinnerThickness: 3.6
}

ContentSpinner.propTypes = {
  style: PropTypes.object,
  show: PropTypes.bool,
  divClasses: PropTypes.string,
  spinnerSize: PropTypes.number,
  spinnerThickness: PropTypes.number
}

export default ContentSpinner;
