import { connect } from 'react-redux';

import {
  updateFilter,
  loadAllStatistics
} from 'store/reducer/statisticsReducer';

import Dashboard from './Dashboard';

const mapStateToProps = (state) => ({
  statisticsFilter: state.statisticsData.filters,
  conversation: state.statisticsData.conversation,
  leadGeneration: state.statisticsData.leadGeneration,
  transaction: state.statisticsData.transaction,
  feedback: state.statisticsData.feedback,
  userHotelList: state.identity.hotels || [],
  activeHotelId: state.hotel
});

// dispatch actions to redux store
const mapDispatchToProps = (dispatch) => ({
  forceRefresh: async (from, to, hotels, aggregation) => {
    dispatch(updateFilter({ dateFrom: from, dateTo: to, hotels: hotels, aggregation: aggregation }));
    await dispatch(loadAllStatistics(from, to, hotels, aggregation));
  }
});

// container component/smart component (interacts with redux)
const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
